import { Counter } from "./components/Counter";
import { FetchData } from "./components/FetchData";
import { Home } from "./components/Home";
import {AddNote} from "./components/AddNote";
import {GetNotes} from "./components/GetNotes";

const AppRoutes = [
  {
    index: true,
    element: <Home />
  },
  {
    path: '/add-note',
    element: <AddNote />
  },
  {
    path: '/get-notes',
    element: <GetNotes />
  }
];

export default AppRoutes;
