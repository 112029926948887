import React, {Component} from 'react';

export class AddNote extends Component {
    static displayName = AddNote.name;

    constructor(props) {
        super(props);
        this.state = {success: false, title: "", body: ""};
        this.handleTitleChange = this.handleTitleChange.bind(this);
        this.handleBodyChange = this.handleBodyChange.bind(this);
    }

    handleTitleChange(event) {
        this.setState({title: event.target.value});
    }

    handleBodyChange(event) {
        this.setState({body: event.target.value});
    }

    render() {
        return (
            <div>
                <h1>Add Notes</h1>

                <p>Add your ideas, notes, and reminders here.</p>

                <form>
                    <label>
                        Title:
                        <input type="text" name="title" value={this.state.title} onChange={this.handleTitleChange}/>
                    </label>
                    <label>
                        Notes:&nbsp;&nbsp;
                        <textarea name="note" value={this.state.body}
                                  onChange={this.handleBodyChange}
                                  rows={3} style={{width: "800px"}}/>
                    </label>
                </form>

                <button className="btn btn-primary" onClick={() => this.createNote(this.state.title, this.state.body)}>Save</button>
            </div>
        );
    }

    async createNote(title, body) {
        if (title === "" || body === "") {
            window.alert("Both title and note body are required.");
            return;
        }

        const uri = 'notes';
        const requestOptions = {
            method: 'POST',
            headers: {'Content-Type': 'application/json'},
            body: JSON.stringify({title: this.state.title, body: this.state.body})
        };

        const response = await fetch(uri, requestOptions);
        this.setState({success: true, title: "", body: ""});
    }
}
