import React, {Component} from 'react';

export class Home extends Component {
    static displayName = Home.name;
w
    render() {
        return (
            <div>
                <h1>Hello, People!</h1>
                <p>You can store your notes and reminders here.</p>
            </div>
        );
    }
}
