import React, {Component} from 'react';

export class GetNotes extends Component {
    static displayName = GetNotes.name;

    constructor(props) {
        super(props);
        this.state = {notes: [], loading: true};
    }

    componentDidMount() {
        this.populateNotes();
    }

    static renderNoteTable(notes) {
        return (
            <table className='table table-striped' aria-labelledby="tabelLabel">
                <thead>
                <tr>
                    <th>Title</th>
                    <th>Date</th>
                    <th>Note</th>
                </tr>
                </thead>
                <tbody>
                {notes.map(note =>
                    <tr key={note.id}>
                        <td>{note.title}</td>
                        <td>{note.date}</td>
                        <td>{note.body}</td>
                    </tr>
                )}
                </tbody>
            </table>
        );
    }

    render() {
        let contents = this.state.loading
            ? <p><em>Loading...</em></p>
            : GetNotes.renderNoteTable(this.state.notes);

        return (
            <div>
                <h1 id="tabelLabel">Notes</h1>
                <p>Here are the notes that have been added.</p>
                {contents}
            </div>
        );
    }

    async populateNotes() {
        const response = await fetch('notes');
        console.log(response)
        const data = await response.json();
        this.setState({notes: data, loading: false});
    }

    async deleteNote(id) {

        const uri = 'notes';
        const requestOptions = {
            method: 'DELETE',
            headers: {'Content-Type': 'application/json'},
            body: JSON.stringify({id: id})
        };

        const response = await fetch(uri, requestOptions);
        this.setState({success: true});
    }
}
